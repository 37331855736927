<template>
  <div class="centerwrap">
    <div class="centerbox">
      <div class="bannerbox">
        <el-carousel height="385px" :autoplay="false" arrow="always">
          <el-carousel-item v-for="(item, index) in listData" :key="index">
            <img :src="item.pic" @click="handelGopage(item.link)" />
          </el-carousel-item>
        </el-carousel>
        <div class="rightrecommend">
          <div class="recomtop">
            <h2 class="titleh2">
              <span>推荐</span>
            </h2>
            <div class="booklist">
              <router-link
                v-for="item in recommendlistData"
                tag="span"
                :to="{
                  name: 'bookIntroduction',
                  params: { name: item.Identify },
                }"
                >{{ item.Bookname }}
              </router-link>
            </div>
          </div>
          <div class="recombottom">
            <div class="logobox">
              <img src="../../../assets/imgs/logo.png" alt />
              <p>专业知识创作社区</p>
            </div>
            <el-link v-if="oFrom == 2" target="_blank" href="/down/win/beanotes_setup_1.0.0.exe" >
              <el-button type="primary" plain>立即下载</el-button>
            </el-link>
            <el-link v-if="oFrom == 3" target="_blank" href="/down/mac/beanotes_1.0.0.dmg" >
              <el-button type="primary" plain>立即下载</el-button>
            </el-link>
            <el-link v-if="oFrom == 4" target="_blank" href="/down/linux/beanotes_1.0.0_amd64.deb" >
              <el-button type="primary" plain>立即下载</el-button>
            </el-link>
          </div>
        </div>
      </div>
      <div class="rommbook2">
        <h2 class="titleh2">
          <span>推荐图书</span>
        </h2>
        <book-list :set="false" :datalist="bookData"></book-list>
      </div>
      <div class="rommcolumn">
        <h2 class="titleh2">
          <span>推荐专栏</span>
        </h2>
        <column-list :set="false" :oData="oData" :page="false"></column-list>
      </div>
    </div>
  </div>
</template>
<script>
import FoldTop from "@/components/Foldtop/foldTop";
import {
  bannerRequest,
  booksRequest,
  specialGatherRequest,
  getBooksRecommendRequest,
} from "@/api/project.js";

import BookList from "@/views/book/components/Booklist.vue";
import ColumnList from "@/views/teams/components/Columnlist";
import { detectOs } from '@/utils/base.js'

export default {
  components: {
    FoldTop,
    BookList,
    ColumnList,
  },
  data() {
    return {
      listData: [],
      visitData: [],
      bookData: [],
      oData: [],
      recommendlistData: [],
      oFrom: 2, //默认windows
    };
  },
  created() {
    this.getBanner();
    this.getBook();
    this.getList();
    this.getRecommend();
    this.OSnow();
  },
  methods: {
    showtext(e) {
      e.currentTarget.className = "showtext";
    },
    hidetext(e) {
      e.currentTarget.className = "";
    },
    async getBanner() {
      try {
        var _this = this;
        const result = await bannerRequest();
        if (result.status == 200) {
          if (result.data) {
            this.listData = result.data.web;
          }
        }
      } catch (err) {}
    },
    async getRecommend() {
      try {
        var _this = this;
        const result = await getBooksRecommendRequest();
        if (result.status == 200) {
          if (result.data) {
            this.recommendlistData = result.data;
          }
        }
      } catch (err) {}
    },
    async getNotesVisit() {
      try {
        var _this = this;
        const result = await notesVisitRequest();
        if (result.status == 200) {
          if (result.data) {
            this.visitData = result.data;
          }
        }
      } catch (err) {}
    },
    handelGopage(link) {
      var userAgent = navigator.userAgent.toLowerCase();
      window.open(link, "_blank")
    },
    handelGopage2(type, item) {
      if (type == 1) {
        //笔记类型(1文章，2图书)
        this.$router.push({ name: "notesDetail", query: { artid: item.Id } });
      } else {
        console.log(type, item.Identify);
        this.$router.push({
          name: "bookDetail",
          query: { name: item.Identify },
        });
      }
    },
    async getBook(val) {
      try {
        val = val || "1";
        const result = await booksRequest({
          page: val,
        });
        if (result.status == 200) {
          this.bookData = result.data.list;
          this.total = result.data.totalCount;
          this.pageCount = result.data.pageCount;
        }
      } catch (err) {}
    },
    async getList(val) {
      try {
        val = val || "1";
        const result = await specialGatherRequest({
          page: val,
        });
        if (result.status == 200) {
          this.oData = result.data;
        }
      } catch (err) {}
    },
    OSnow(){

      var detectos = detectOs()
      if (detectos == 'mac') {
          this.oFrom = 3
      } else if (detectos == 'win') {
          this.oFrom = 2
      } else if (detectos == 'linux') {
          this.oFrom = 4
      }

    }

  },
};
</script>
<style  lang="less" scoped>
.centerwrap {
  position: relative;
  width: 1200px;
  margin: 30px auto 0;
}
.titleh2 {
  font-size: 18px;
  color: #000000;
  // padding: 20px 0;
  border-bottom: 1px solid #dedfe0;
  span {
    border-bottom: 1px solid #2f95e3;
    margin-bottom: -1px;
    line-height: 40px;
    display: inline-block;
  }
}
.listbox {
  .tcard {
    overflow: hidden;
    cursor: pointer;
    padding-bottom: 23.96%;
    height: 0;
  }
  .cardone {
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }

    .titleh2 {
      position: absolute;
      box-sizing: border-box;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.5s;
      opacity: 0;
      font-size: 20px;
      color: #fff;
      span {
        display: block;
        text-align: center;
      }
    }
    .showtext .titleh2 {
      opacity: 1;
      color: #fff;
    }
  }
  .cardtwo {
    text-align: center;
    h2 {
      margin-top: 28%;
      font-size: 20px;
    }
    p {
      font-size: 14px;
    }
  }
}
.el-carousel__item img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
/deep/.el-carousel__arrow {
  background: none;
  font-size: 40px;
}
.el-carousel {
  width: 675px;
  height: 385px;
  float: left;
}
.bannerbox {
  margin-bottom: 30px;
  clear: both;
  overflow: hidden;
}
.rightrecommend {
  float: right;
  width: 507px;
  height: 385px;
  position: relative;
  .recomtop {
    border: 1px solid#DEDFE0;
    height: 235px;
    box-sizing: border-box;
    padding: 11px 0;
  }
  .titleh2 {
    margin: 0 30px;
  }
  .recombottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    border: 1px solid#DEDFE0;
    height: 132px;
    box-sizing: border-box;
    padding: 20px 40px;
    .logobox {
      width: 240px;
      height: 80px;
      float: left;
      margin-top: 7px;
      position: relative;
      & > p {
        position: absolute;
        bottom: 0;
        left: 67px;
        word-break: keep-all;
        font-size: 14px;
        color: #666;
      }
      img {
        width: 160px;
      }
    }
    /deep/.el-button {
      width: 170px;
      margin: 30px auto;
    }
  }
}
.booklist {
  padding: 20px 0;
  margin: 0 30px;
  border-bottom: 1px dotted #dedef0;
  & > span {
    padding-right: 15px;
    border-right: 1px solid #dedef0;
    margin: 10px 15px 10px -1px;
    display: inline-block;
    font-size: 15px;
    color: #666;
    cursor: pointer;
  }
}
.rommbook2 {
  /deep/.booklist {
    margin: 0 -15px;
    padding: 30px 0;
    li {
      width: 16.66667%;
      .onebox {
        background: none;
        box-shadow: none;
        padding: 0 10px;
        .imgbox {
          padding: 4px;
        }
      }
    }
  }
}
.rommcolumn {
  /deep/.booklist {
    margin: 0 -10px;
    padding: 30px 0;
    li {
      width: 20%;
      .onebox {
        box-shadow: none;
        border: 1px solid #dedef0;
        border-radius: 2px;
      }
    }
  }
}
</style>